import Vue from 'vue';
import Vuex from 'vuex';
// import 'es6-promise/auto';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
      changeLang (state, payload) {
        localStorage.setItem('currentLanguage', payload.id);
        localStorage.setItem('currentLanguageIcon', payload.icon);
        window.location.reload();
      }
    },
    actions: {
      setLang ({ commit }, payload) {
        commit('changeLang', payload);
      }
    },
    modules: {
    }
});

