<template>

    <b-row id="startPage" :style="heightStyle" style="z-index: 5;padding-left: 86px" >





      <b-col class=" " >
        <b-row >

          <!--
          <b-col  cols="12" >
            <earth />
          </b-col>-->


          <b-col  cols="12" class="" id="main"  >

            <rolling-text id="header" fill="BIT" stroke="UNFX"/>


            <b-row class="topTitleBox" >


                <b-row   class=" "  id="topCaptions">

                  <b-col cols="12" md="6" lg="6" xl="6" sm="12" align-self="end" class="titleBox"  >

                    <h4 class="upper textLeft nexaExtraBold fontsize-32 text-gradient title-header"   >

                      Simple and secure way to supervise your digital assets.
                    </h4>

                    <p class="textLeft nexaReqular fontsize-24 mt-4 title-description">
                      UNFXBIT offers digital asset management tools and a blockchain-based business development platform.                    </p>


                  </b-col>
                </b-row>
                <b-row class="" align-h="start"   >
                  <b-col cols="12"  sm="12" md="12" lg="6"  xl="6"   >
                    <b-row class="mt-2   " align-h="start"    >
                      <b-col cols="12" sm="12" md="4" lg="5" xl="4"   >
                        <custom-button link="services" text="Services" variant="bg-primary-custom" theme="light" class="mt-2 mb-auto p-2"/>
                      </b-col>
                      <b-col  cols="12" sm="12" md="4" lg="5" xl="4"    >
                        <custom-button link="contactUs" text="Contact us" variant="bg-accent-custom" theme="dark" class="mt-2 mb-auto p-2"/>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

              <div class="container scrollButtonContainer">

                <b-row align-v="end" class="d-flex align-items-center align-content-center justify-content-center mouse-scroll-icon-container align-items-end">
                  <a class="mouse" style="width: 52px;" @click="scrollTo('services')">
                  </a>
                  <p id="scroll">Scroll</p>
                </b-row>
              </div>


            </b-row>

            <b-row id="headerEnd" > </b-row>
            <rolling-text id="visionRoller"    fill="Services" stroke="UNFXBIT" style="margin-top: 200px"/>



            <b-col  cols="12 "   >
              <shader />
            </b-col>


            <rolling-text id="conceptRoller"  fill="UNFX" stroke="COIN"/>


            <b-col  cols="12 " >
              <Exchange />
            </b-col>


            <rolling-text id="conceptRoller"  fill="Wallet" stroke="Custody"/>


            <b-col  cols="12 " >
              <WalletCustody />
            </b-col>


            <rolling-text id="conceptRoller"  fill="BLOCKCHAIN" stroke="Explorer"/>


            <b-col  cols="12 " >
              <Explorer />
            </b-col>

            <rolling-text id="conceptRoller"  fill="Node" stroke="view"/>


            <b-col  cols="12 " >
              <Nodeview />
            </b-col>
            <rolling-text id="conceptRoller"  fill="CONTACT" stroke="UNFXBIT"/>


            <b-col  cols="12 " >
              <ContactUs />
            </b-col>


<!--            <rolling-text id="conceptRoller"  fill="ROADMAP" stroke="UNFXBIT"/>-->
<!--            <b-col  cols="12 " >-->
<!--              <RoadMap />-->
<!--            </b-col>-->

<!--            <rolling-text id="conceptRoller"  fill="PARTNERS" stroke="UNFXBIT"/>-->



<!--            <b-col  cols="12 " >-->
<!--              <partner />-->
<!--            </b-col>-->

            <b-col  cols="12 " >
              <Footer />
            </b-col>












          </b-col>
        </b-row>




      </b-col>



    </b-row>

</template>





<script>
import CustomButton from '../components/Button';
import ShapeOverlays from '../assets/js/sidebar';
import Shader from "@/components/Shader";
import RollingText from "@/components/RollingText";
import Exchange from "@/components/Exchange";
import WalletCustody from "@/components/WalletCustody.vue";
import Explorer from "@/components/Explorer.vue";
import Nodeview from "@/components/Nodeview.vue";
import ContactUs from "@/components/ContactUs.vue";
// import Partner from "@/components/Partner";
// import RoadMap from "@/components/RoadMap.vue";
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: {
    Shader,
    CustomButton,
    RollingText,
    Exchange,
    WalletCustody,
    Explorer,
    Nodeview,
    ContactUs,
   /* RoadMap,
    Partner,*/
    Footer,
  },
  created() {




  },

  data() {
    return {
      scrollPositionBase: 250,
      scrollPosition: global.globalScrollPosition,
      height: 0,
      width: 0,
      shader: null

    };
  },
  methods: {
    scrollTo(id) {
      global.scrollTo(id)
    },
    reportWindowSize() {
      this.height = window.innerHeight
      this.width = window.innerWidth
    }
  },
  mounted() {
    window.onresize = this.reportWindowSize;
  /*  this.mainScrollBar = Scrollbar.init(document.querySelector('#app'), {
      dumping: 0.01
    });






    this.mainScrollBar.addListener((status) => {
      this.scrollPosition = (this.scrollPositionBase - status.offset.y)
    });*/



    this.$root.$on('onScroll', (status) => {
      this.scrollPosition = (this.scrollPositionBase - status.offset.y)
    });

    this.height = window.innerHeight;
    this.width = window.innerWidth;

    if(this.width < 700) {
      this.scrollPositionBase = 250
    }




    const elmHamburger = document.querySelector('.hamburger');
    const gNavItems = document.querySelectorAll('.global-menu__item');
    const elmOverlay = document.querySelector('.shape-overlays');

    const overlay = new ShapeOverlays(elmOverlay);

    elmHamburger.addEventListener('click', () => {
      if (overlay.isAnimating) {
        return false;
      }
      overlay.toggle();
      if (overlay.isOpened === true) {
        elmHamburger.classList.add('is-opened-navi');
        for (let i = 0; i < gNavItems.length; i++) {
          gNavItems[i].classList.add('is-opened');
        }
      } else {
        elmHamburger.classList.remove('is-opened-navi');
        for (let i = 0; i < gNavItems.length; i++) {
          gNavItems[i].classList.remove('is-opened');
        }
      }
    });

  },
  computed: {
    itemMoverStyle() {
      return {
        "transform": `translate3d(${this.scrollPosition}px, 0px, 0px)`,
        "font-size": '9rem',
        "font-weight": `bold`,
        "text-transform": 'uppercase',
        "transition-property": 'transform',
        "transition-timing-function": 'cubic-bezier(0.15, 0, 0.55, 1)',
        "transition-duration": '0s',
        "transition-delay": '0s',
      };
    },
    heightStyle() {
      return {
        "height": `${this.height}px`
      };
    }
  }
}
</script>

<style>

:root {
  --width: 100%;
  --height: -800px;
  --navBarSize: 96px;
}

#app {
  font-family:  Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  z-index: 5;
  width: 100%;
  overflow: auto;
}

#main {

}


.A-pattern-title__item:nth-child(odd) {
  color: transparent;
  -webkit-text-stroke: 1.5px #ffffff;
  transition: -webkit-text-stroke-color .5s ease-in-out;
  transition-duration: 5s;
}
.A-pattern-title__mover {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 10000px;
  margin-left: -16rem;
  transition: -webkit-text-stroke-color .5s ease-in-out;
  transition-duration: 5s;
  font-family: NexaExtraBold, Avenir, Helvetica, Arial, sans-serif;
}
.A-pattern-title--stroke .A-pattern-title__item {
  white-space: nowrap;
  transition: -webkit-text-stroke-color .5s ease-in-out;
  transition-duration: 5s;
}
.--pattern-title {
  line-height: 1;
  font-weight: 200;
  color: #f7f7fa;
  transition: -webkit-text-stroke-color .5s ease-in-out;
  transition-duration: 5s;
  overflow: hidden
}


</style>
