<template>

  <div class="demo-5">
    <div style="position: fixed;top: 0;left: 0;right: 0;z-index: 99;pointer-events: none">

      <div class="content content--demo-5">


        <div class="global-menu" style="z-index: 99;font-size: 12px">

          <div class="global-menu__wrap">
            <br/><br/><br/>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('header')" href="#">
              <b style="color: white">
                UNFXBIT.COM
              </b>
            </a>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('exchange')" href="#">Exchange</a>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('walletCustody')" href="#">Wallet Custody</a>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('explorer')" href="#">Blockchain Explorer</a>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('nodeView')" href="#">Blockchain Infrastructure</a>
            <a class="global-menu__item global-menu__item--demo-5"  @click="goTo('contactUs')" href="#">Contact US</a>
<!--            <a class="global-menu__item global-menu__item&#45;&#45;demo-5"  @click="goTo('roadMap')" href="#">Roadmap</a>
            <a class="global-menu__item global-menu__item&#45;&#45;demo-5"  @click="goTo('partners')" href="#">Partners</a>-->
          </div>
        </div>
        <svg class="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path class="shape-overlays__path"></path>
          <path class="shape-overlays__path"></path>
        </svg>
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
          <linearGradient id="my-cool-gradient" gradientTransform="rotate(0)">
            <stop offset="0%"  stop-color="rgba(33,4,54,1)" />
            <stop offset="70%"  stop-color="rgba(54,4,52,1)" />
            <stop offset="100%"  stop-color="rgba(88,4,50,1)" />
          </linearGradient>



        </svg>


        <transition name="fade">
          <div class="menu-background">
            &nbsp;
          </div>
        </transition>



      </div>
    </div>

  </div>
</template>


<script>

export default {
  name: 'Menu',
  components: {

  },
  created() {




  },
  mounted() {


/*

     document.querySelector('.hamburger')
        .addEventListener("click", () => {
          setTimeout(() => {
            let menuBg =  document.getElementsByClassName('menu-background');

            if(menuBg && menuBg.length > 0)
              menuBg = menuBg[0]
            else {
              return
            }

            if (parseInt(menuBg.style.opacity + '') === 1) {
              menuBg.style.opacity = 0;
            }
            else {
              menuBg.style.opacity = 1;
            }
          }, 500);


    });
*/



  },
  destroyed() {

  },

  data() {
    return {
    };
  },
  methods: {

    goTo(section) {
      console.log('Menu.vue goTo ' + section)
      global.scrollTo(section, 1000)
      document.querySelector('.hamburger').click();
    }



  },
}
</script>

<style>



</style>
