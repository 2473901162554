<template>

  <b-row >
    <b-row  >
      <section class="token " style="min-height: calc(100vh - 7rem);  margin-top: 48px;"  >


      <h3 class="upper  nexaExtraBold fontsize-32 text-gradient title" id="exchange">
        Exchange
      </h3>

      <b-row>
        <b-col cols="12" sm="12" md="12" lg="6" xl="6"  style="padding-left: 66px;">
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="Spot Trading"/>
              <span>Spot Trading</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="Super Safe"/>
              <span>Swap</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="Swap"/>
              <span>OTC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="Trading bot"/>
              <span>AML protection</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="Native Apps"/>
              <span>Native Apps</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
              <img src="../assets/images/icons/ticket.svg" alt="High performance"/>
              <span>High performance</span>
            </b-col>
          </b-row>
          <b-row class="" align-h="start"   >
            <div class="d-flex align-items-start flex-column" >
              <a href="https://unfxcoin.com" target="_blank" style="width: 100%;max-width: 350px; margin-top: 42px;margin-bottom: 42px">
                <div   style="width: 100%" >
                  <div class="rect-button">
                    <div     class="rect-button text-light bg-accent-custom bg-primary-custom" >
                      <span class="nexaExtraBold"> Open App  </span>
                    </div>
                  </div>

                </div>
              </a>
            </div>
          </b-row>
        </b-col>
        <b-col  cols="12" sm="12" md="12" lg="6" xl="6" class="gallery"  >
          <b-row   class=" d-flex justify-content-start align-items-center" style="padding-left: 32px">
            <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/spot-trading.png"  alt="icon"/>
            </b-col> <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/aml.png"  alt="icon"/>
            </b-col>
            <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/linux.png"  alt="icon"/>
            </b-col>
            <b-col cols="3" class="  exchangeTechItem">
              <img src="@/assets/images/exchange/techs/otc.png"  alt="icon"/>
            </b-col>
            <b-col cols="3" class=" exchangeTechItem">
              <img src="@/assets/images/exchange/techs/android.png"  alt="icon"/>
            </b-col>
            <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/ios.png"   alt="icon"/>
            </b-col>
            <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/windows.png"  alt="icon"/>
            </b-col>
            <b-col cols="3" class="  exchangeTechItem">
              <img src="@/assets/images/exchange/techs/performance.png"  alt="icon"/>
            </b-col>

            <b-col cols="3" class=" exchangeTechItem ">
              <img src="@/assets/images/exchange/techs/swap.png"   alt="icon"/>
            </b-col>

          </b-row>
        </b-col>
        <div  id="exchangeEnd"></div>
      </b-row>





    </section>
    </b-row>
  </b-row>
</template>


<script>

export default {
  name: 'Exchange',
  components: {
  },
  created() {

  },
  mounted() {



    setTimeout(() => {
      global.mainScrollBar.addListener(() => {
      });
    },1000);



  },
  destroyed() {

  },

  data() {
    return {

      title: ''
    }
  },
  methods: {
  },
}
</script>

<style>
.highcharts-label text {
  color: #ab198c;
  fill: #0BFFBF;
}


</style>
