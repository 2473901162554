<template>
  <div class="d-flex align-items-start flex-column" style="width: 100%;">
    <div @click="scrollTo(link)" style="width: 100%" >
      <div class="rect-button">
        <div    :class="className" >
          <span class="nexaExtraBold">{{ this.text }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    link: String,
    target: String,
    text: String,
    variant: String,
    theme: String
  },
  data(){
    return{
      className: 'rect-button '
    };
  },
  mounted() {

    this.className += this.variant

    if(this.theme) {
      if(this.theme==='light')
        this.className += ` text-dark`
      else this.className += ` text-light`
    }

  },
  methods: {

    scrollTo(section) {

      console.log(section)
      setTimeout(() => {
        global.scrollTo(section)
      },500)
    }
  }
};
</script>

<style scoped>

.rect-button {
  cursor: pointer;
  --cursor: hover;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  min-width: 200px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 0;
  text-transform: uppercase;
  color:white;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  -webkit-clip-path: polygon(0 calc(100% - 1.25rem),0 0,calc(100% - 1.25rem) 0,100% 1.25rem,100% 100%,1.25rem 100%);
  clip-path: polygon(0 calc(100% - 1.25rem),0 0,calc(100% - 1.25rem) 0,100% 1.25rem,100% 100%,1.25rem 100%);


  background: #F13FA8;
  background: linear-gradient(to right,#F13FA8,#04A9FA);
  background: -webkit-linear-gradient(to right,#f13fa8 0%,#04a9fa 100%);

}

.rect-button:hover {
  color: #09a17b;
}
</style>