<template>


  <b-row >
    <b-row  >
      <b-col class="shader">
        <b-row cols-lg="frame">
          <section class="token " style="min-height: calc(100vh - 7rem);  margin-top: 48px;"  >


          <h3 class="upper  nexaExtraBold fontsize-32 text-gradient title" id="explorer">
            Blockchain Explorer
          </h3>

          <b-row>
            <b-col cols="12" sm="12" md="12" lg="6" xl="6"  style="padding-left: 66px;">
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>All in one explorer</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Testnet explorer</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Real-time analytics</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Easy to use</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>User-Friendly</span>
                </b-col>
              </b-row>
              <b-row class="" align-h="start"   >
                <div class="d-flex align-items-start flex-column" >
                  <a href="https://explorer.unfxbit.com" target="_blank" style="width: 100%;max-width: 350px;margin-top: 42px;margin-bottom: 42px">
                    <div   style="width: 100%" >
                      <div class="rect-button">
                        <div     class="rect-button text-light bg-accent-custom bg-primary-custom" >
                          <span class="nexaExtraBold"> Open App  </span>
                        </div>
                      </div>

                    </div>
                  </a>
                </div>
              </b-row>


            </b-col>
            <b-col  cols="12" sm="12" md="12" lg="6" xl="6" class="gallery"   >
              <b-row >
                <b-col cols="12" class=" d-flex justify-content-center align-items-center" >
                  <b-carousel
                      id="carousel-1"
                      v-model="slide"
                      :interval="4000"
                      controls
                      indicators
                      fade
                      background="#00000000"
                      img-width="1024"
                      img-height="480"
                      style="text-shadow: 1px 1px 2px #000000;"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                        style="max-height: 470px"
                        caption="All in one explorer"
                        text="Explorer blockchain assets, transactions, addresses, and more"
                        img-src="/images/slider/explorer1.png"
                    ></b-carousel-slide>



                    <b-carousel-slide
                        style="max-height: 470px"
                        caption="Testnet explorer"
                        text="Browse the mainnet and testnet blockchains at the same environment"
                        img-src="/images/slider/explorer2.png"
                    ></b-carousel-slide>


                    <b-carousel-slide
                        style="max-height: 470px"
                        caption="Real-time analytics"
                        text="Real-time analytics and statistics"
                        img-src="/images/slider/explorer3.png"
                    ></b-carousel-slide>



                  </b-carousel>
                </b-col>

              </b-row>
            </b-col>
            <div  id="explorerEnd"></div>
          </b-row>





        </section>
      </b-row>
    </b-col>
    </b-row>
  </b-row>
</template>


<script>

export default {
  name: 'Explorer',
  components: {
  },
  created() {

  },
  mounted() {



    setTimeout(() => {
      global.mainScrollBar.addListener(() => {
      });
    },1000);



  },
  destroyed() {

  },

  data() {
    return {

      slide: 0,
      sliding: null,
      title: ''
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
}
</script>

<style>
.highcharts-label text {
  color: #ab198c;
  fill: #0BFFBF;
}
.carousel-caption {
  color: white;
  backdrop-filter: blur(5px);
}


</style>
