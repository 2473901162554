<template>


  <b-row >
    <b-row  >
      <b-col class="shader">
        <b-row cols-lg="frame">
          <section class="token " style="min-height: calc(100vh - 7rem);  margin-top: 48px;"  >


          <h3 class="upper  nexaExtraBold fontsize-32 text-gradient title" id="contactUs">
            Get in touch
          </h3>

          <b-row>
            <b-col cols="12" sm="12" md="12" lg="6" xl="6"  style="padding-left: 66px;">
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <GmapMap
                      :center="{lat:41.075804, lng:28.971582}"
                      :zoom="12"
                      :options="{zoomControl: false, mapTypeControl: false, streetViewControl: false}"
                      map-type-id="terrain"
                      style="width: 100%; height: 300px"
                  >
                    <GmapMarker v-for="(marker, index) in markers" :key="index"
                                :position="marker.position"


                        :clickable="false"
                        :draggable="false"
                      :icon="marker.icon">

                    </GmapMarker>

                  </GmapMap>
                </b-col>
              </b-row>

              <br/>
              <br/>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span  style="color: rgba(255,255,255,0.86);font-weight: lighter; ">
                     MERKEZ MAH. KAĞITHANE CAD.
                    <br/>
                    Y OFIS NO: 5 İÇ KAPI NO:   71
                    <br/>
                    KAĞITHANE/ İSTANBUL
                    </span>
                </b-col>
              </b-row>

              <br/>
              <br/>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                    <span  style="color: rgba(255,255,255,0.86);font-weight: lighter; ">
                      License Number 661903   <br/>
                      FINANCIAL Activities ,   <br/>
                      Stock exchange and trade  <br/>
                      (TOBB) 9239
                    </span>

                </b-col>
              </b-row>
            </b-col>
            <b-col  cols="12" sm="12" md="12" lg="6" xl="6" class="gallery"  >
              <b-row   class=" d-flex justify-content-start align-items-center" style="padding-left: 32px">

                <b-col  cols="12" sm="12" md="12" lg="6" xl="6">



                  <b-form method="POST" @submit.prevent="handleSubmit" style="color: white;text-align: left">
                    <b-form-group label="Name" label-for="name">
                      <b-form-input v-model="formData.name" name="name" id="name" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Company" label-for="company">
                      <b-form-input v-model="formData.company" name="company" id="company"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                      <b-form-input v-model="formData.email"  name="email" type="email" id="email" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Message" label-for="message">
                      <b-form-textarea v-model="formData.message"  name="message"  id="message" rows="4" required></b-form-textarea>
                    </b-form-group>
                    <br/>

                    <b-button type="submit" style="width: 100%;background-color: transparent;border: none">
                      <div   style="width: 100%" >
                        <div class="rect-button">
                          <b-spinner small label="Small Spinner" v-if="showSpinner"></b-spinner>
                          <div     class="rect-button text-light bg-accent-custom bg-primary-custom" >
                            <span class="nexaExtraBold"> Send message  </span>
                          </div>

                        </div>

                      </div>
                    </b-button>

                  </b-form>

                  <br/>
                  <b-alert v-model="showSuccessAlert" variant="success" >
                    Your message has been sent
                  </b-alert>
                  <b-alert v-model="showFailedAlert" variant="danger" >
                    An error occurred while sending your message, please try again later.
                  </b-alert>

                </b-col>

              </b-row>
            </b-col>
            <div  id="contactUsEnd"></div>
          </b-row>





        </section>
      </b-row>
    </b-col>
    </b-row>
  </b-row>
</template>


<script>

import axios from "axios";
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'ContactUs',
  components: {

  },
  created() {

  },
  mounted() {



    setTimeout(() => {
      global.mainScrollBar.addListener(() => {
      });
    },1000);



  },
  destroyed() {

  },

  data() {
    return {
      formData: {
        name: '',
        company: '',
        email: '',
        message: ''
      },
      slide: 0,
      sliding: null,
      title: '',
      showSuccessAlert: false,
      showFailedAlert: false,
      showSpinner: false,
      markers: [
        {
          position: { lat: 41.075804, lng: 28.971582 },
          icon: {
            url: require('@/assets/marker.png'), // Path to your custom image
            scaledSize: {width: 64, height: 64},
          },
          label: {
            text: 'UNFXCO',
            color: 'black',
            fontWeight: 'bolder',
            fontSize: '18px',
          },
        },
        // Add more markers as needed
      ],
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    async handleSubmit() {
      try {
        this.showSpinner = true
        const response = await axios.post('contact/submit-form', this.formData);
        console.log(response.data); // Handle response as needed
        this.showSuccessAlert = true
        this.showFailedAlert = false
        this.showSpinner = false
        this.formData = {
              name: '',
              company: '',
              email: '',
              message: ''
        }
      } catch (error) {
        this.showSuccessAlert = false
        this.showFailedAlert = true
        this.showSpinner = false
        console.error('Error submitting form:', error);
      }
    }
  },
}
</script>

<style>
.highcharts-label text {
  color: #ab198c;
  fill: #0BFFBF;
}


</style>
