import Vue from 'vue'
import App from './App.vue'

import { store } from './store';
Vue.config.productionTip = false

import VueRouter from 'vue-router';



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'



import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'

Vue.use(HighchartsVue, {
  highcharts: Highcharts
})

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCQQ1mwsGDG78qVwSi0FDvVsnPnTSVt0vg',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

import './assets/css/normalize.css'
import './assets/css/app.scss'
import './assets/css/sidebar.css'
import './assets/js/sidebar'
import './assets/js/easings'


import './assets/css/organicShape.css'
import './assets/js/organicShape'





import './assets/js/shaders/shader'
import './assets/css/shader.sass'
import './assets/css/shaders-on-scroll.css'


import './assets/css/scroll.scss'
import './assets/css/timeLine.css'












import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas  )

Vue.component('font-awesome-icon', FontAwesomeIcon)


// Multi Language Add
import en from './locales/en.json';
import es from './locales/fa.json';
import { defaultLocale, localeOptions } from './constants/config';
import VueI18n from 'vue-i18n';
import router from './router'
import ShapeOverlays from "@/assets/js/sidebar";




// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueI18n);
Vue.use(VueRouter);



global.darkButtonClass = 'bg-dark-custom'
global.globalScrollPosition = 250
global.mainScrollBar = null
global.scrollTo = function (id, duration = 1000) {
  const elem = document.getElementById(id)
  if(!elem) {
    console.log(id, 'not found')
    return
  }

  //reset scrollbar
  global.mainScrollBar.scrollTo(0, 0 , 0)
  let  elemRect =  elem.getBoundingClientRect();
  if(global.mainScrollBar)
    global.mainScrollBar.scrollTo(0, elemRect.top , duration)
}

global.elementVisibilityStatus = function(idOrClass) {
  const element = document.querySelector(idOrClass);

  if(!element) return 0
  const position = element.getBoundingClientRect();

  // checking whether fully visible
  if(position.top >= 0 && position.bottom <= window.innerHeight) {
    return 1
  }
  else  if(position.top < window.innerHeight && position.bottom >= 0) {
    return 0.5
  }
  return 0
}

global.closeMenu = function () {

  const elmHamburger = document.querySelector('.hamburger');
  const gNavItems = document.querySelectorAll('.global-menu__item');
  const elmOverlay = document.querySelector('.shape-overlays');

  const overlay = new ShapeOverlays(elmOverlay);

  if (overlay.isAnimating) {
    return false;
  }
  overlay.close();
  if (overlay.isOpened === true) {
    elmHamburger.classList.add('is-opened-navi');
    for (let i = 0; i < gNavItems.length; i++) {
      gNavItems[i].classList.add('is-opened');
    }
  } else {
    elmHamburger.classList.remove('is-opened-navi');
    for (let i = 0; i < gNavItems.length; i++) {
      gNavItems[i].classList.remove('is-opened');
    }
  }


}





const messages = { en: en, es: es };
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true
});




new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
