<template >


  <div   style="width: 100%;max-width:100%;background-color: rgba(33, 4, 54, 0.67);">
    <b-row>
      <b-container id="app" >
        <b-row class="" >
          <b-col cols="12" md="auto" class="  " style="min-width: 90px;z-index: 999;height: 100% ">
            &nbsp;
          </b-col>
          <b-col  class="" >

            <router-view/>


          </b-col>
        </b-row>

      </b-container>
    </b-row>


      <Menu/>

    <transition name="fade">
      <div class="topImage" v-if="displayHeroImage && !isMobile && !isTablet">
        <div class="glitch ">
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
          <div class="glitch__img"></div>
        </div>

      </div>
    </transition>
    <transition name="fade">
      <div class="hexagon-background " v-if="displayBlueBackground" >
        <div style="width: 100%;height: 100%;background:rgba(26,1,33,0.74);"> &nbsp;  </div>
      </div>
    </transition>


    <transition name="fade">
      <div class="purple-background " v-if="displayPurpleBackground"   >  </div>
    </transition>
    <transition name="fade">
      <div class="meta-human-background " v-if="displayMetaHumanBackground"   >



      </div>
    </transition>
    <transition name="fade">
      <div class="concept-background " v-if="displayConceptBackground"   >

        <div class="videoPlayer d-flex align-items-center justify-content-center" > <!---->
          <div class="player-container">
<!--
            <video  id="visionVideo1"  muted  autoplay style="filter: grayscale(50%) brightness(25%) sepia(100%) hue-rotate(-100deg) saturate(250%) contrast(0.8);">
              <source src="./assets/video/city-look.mp4" type="video/mp4">
            </video>
-->

          </div>


        </div>

      </div>
    </transition>









      <div  v-if="videoIsPlaying  && false">
        <div class="videoPlayer d-flex align-items-center justify-content-center" > <!---->
          <div class="player-container">
<!--            <video  id="visionVideo"  @click="toggleVideo"  autoplay>
              <source src="./assets/video/vision.mp4" type="video/mp4">
            </video>-->

          </div>


        </div>

        <div class="videoPlayerClose   d-flex align-items-center justify-content-center" @click="closeVideoPlayer"   >
          <font-awesome-icon icon="times" size="4x" style="width: 100%; color: #888aab" class="m-3"   />
        </div>
      </div>



    <b-row class="fixedTop d-none">
      <b-col class="item">
        <font-awesome-icon icon="file-pdf"  size="lg"     />
        <span>
          white paper
        </span>
      </b-col>

      <b-col class="item">
        <font-awesome-icon icon="coins"  size="lg"     />
        <span>
          Buy
        </span>
      </b-col>

      <b-col class="item">
        <font-awesome-icon icon="play-circle"  size="lg"     />
        <span>
          Trailer
        </span>
      </b-col>

    </b-row>

    <div class="leftSideBar " style="z-index: 999">
      <div class="d-flex align-items-start flex-column" style="height: var(--height)">
        <div class="mb-auto p-2 d-flex align-items-center align-content-center hand">

          <img src="./assets/images/unfxbit-logo.png" style="width: 100%;padding: 4px" @click="goTo('header')"/>

        </div>
        <div  class="mb-auto p-2   ">


        </div>
        <div class=" p-2 d-flex align-items-center align-content-center text-white">
          <b-row>
            <!-- <b-col cols="12"  >
              <font-awesome-icon icon="play-circle"  size="lg"  class="hoverTextAccent hand   "  style="width: 100%;" @click="openVideoPlayer"  />
            </b-col>


            <b-col cols="12" class="mt-3 mb-2  hoverTextAccent hand"   >
              <font-awesome-icon icon="globe"  size="lg" style="width: 100%;"   />
            </b-col> -->


         <div class="hamburger hamburger--demo-5 js-hover  d-flex align-items-center align-content-center " style="top: 45%">
            <div class="hamburger__line hamburger__line--01">
              <div class="hamburger__line-in hamburger__line-in--01 hamburger__line-in--demo-5"></div>
            </div>
            <div class="hamburger__line hamburger__line--02">
              <div class="hamburger__line-in hamburger__line-in--02 hamburger__line-in--demo-5"></div>
            </div>
            <div class="hamburger__line hamburger__line--03">
              <div class="hamburger__line-in hamburger__line-in--03 hamburger__line-in--demo-5"></div>
            </div>
            <div class="hamburger__line hamburger__line--cross01">
              <div class="hamburger__line-in hamburger__line-in--cross01 hamburger__line-in--demo-5"></div>
            </div>
            <div class="hamburger__line hamburger__line--cross02">
              <div class="hamburger__line-in hamburger__line-in--cross02 hamburger__line-in--demo-5"></div>
            </div>
          </div>
          </b-row>



        </div>
      </div>

    </div>
    <b-col  cols="12 " >
      <CanvasBackground />
    </b-col>


  </div>







</template>

<script>
import ShapeOverlays from "@/assets/js/sidebar";
import CanvasBackground from "@/components/CanvasBackground";
import Scrollbar from 'smooth-scrollbar';
import Menu from '@/components/Menu';
/*

scroll
header
preloader
features
services
exchanges
AGGREGATORS
partners
roadmap
*/

export default {
  name: 'App',
  components: {
    CanvasBackground,
    Menu
  },
  created() {
    const cssVariableManager = document.querySelector(':root');

    cssVariableManager.style.setProperty('--width', window.innerWidth + 'px');
    cssVariableManager.style.setProperty('--height', window.innerHeight + 'px');



    if(this.width <= 900) {

      this.isTablet = true
    }
    else if(this.width <= 500) {
      this.isMobile = true
    }


    function reportWindowSize() {
      this.height = window.innerHeight
      this.width = window.innerWidth

      cssVariableManager.style.setProperty('--width', window.innerWidth + 'px');
      cssVariableManager.style.setProperty('--height', window.innerHeight + 'px');




      if(this.width <= 900 && this.width > 500) {

        this.isTablet = true
        this.isMobile = false
      }
      else if(this.width <= 500) {
        this.isMobile = true
        this.isTablet = false
      }
      else {
        this.isTablet = false
        this.isMobile = false
      }
      console.log('Body height changed:', window.innerHeight, this.isTablet, this.isMobile, this.displayHeroImage)
    }

    window.onresize = reportWindowSize;



    if(this.width <= 900) {

      this.isTablet = true
    }
    else if(this.width <= 500) {
      this.isMobile = true
    }


    // setTimeout(() => {
    //   global.scrollTo('nodeView')
    // }, 500);







  },
  destroyed() {

  },

  data() {
    return {
      scrollPositionBase: 500,
      scrollPosition: 50,
      height: window.innerHeight,
      width: window.innerWidth,
      visible: true,
      displayShader: false,
      darkBackground: false,
      displayHeroImage: true,
      displayBlueBackground: false,
      displayPurpleBackground: false,
      displayMetaHumanBackground: false,
      displayConceptBackground: false,
      lastScrollPosition: 0,
      isMobile: false,
      isTablet: false,
      videoIsPlaying: false,
      videoState: 'play_XXXXXXXX',
      currentStage: '',
      currentLevel: 0,
    }

  },
  methods: {

    scrollManager(status) {

      this.$root.$emit('onScroll', status);

      this.lastScrollPosition = status.offset.y
      //let timeout = null



 
     if(global.elementVisibilityStatus('#header') > 0 || global.elementVisibilityStatus('#headerEnd') > 0) {
           this.currentStage =  'header'
      }
      if(global.elementVisibilityStatus('#services') > 0 || global.elementVisibilityStatus('#servicesEnd') > 0) {
           this.currentStage =  'vision'
      }
      if(global.elementVisibilityStatus('#exchange') > 0 ||  global.elementVisibilityStatus('#exchangeEnd') > 0 ) {
        this.currentStage =  'exchange'
      }
      if(global.elementVisibilityStatus('#metaverse') > 0 || global.elementVisibilityStatus('#metaverseEnd') > 0) {
           this.currentStage =  'metaverse'
      }
      if(global.elementVisibilityStatus('#play2earn') > 0 || global.elementVisibilityStatus('#play2earnEnd') > 0) {
           this.currentStage =  'play2earn'
      }
      if(global.elementVisibilityStatus('#partner') > 0 ||  global.elementVisibilityStatus('#partnerEnd') > 0 ) {
           this.currentStage =  'partner'
      }
      if(global.elementVisibilityStatus('#footer') > 0 ) {
           this.currentStage =  'footer'
      }

    

      if(this.currentStage === 'vision'  ) {
        if(document.getElementById("shaderBox").style.visibility === 'hidden') {
          document.getElementById("shaderBox").style.visibility =  'visible'
          document.getElementById("shaderBox").style.opacity = "1";
          document.getElementById("shaderBox").style.display =  'table'
        }
      }
      else {
        if(document.getElementById("shaderBox").style.visibility === 'visible') {
          document.getElementById("shaderBox").style.visibility =  'hidden'
          document.getElementById("shaderBox").style.opacity = "0";
        }
      }

      if(this.currentStage === 'exchange' || this.currentStage === 'partner' || this.currentStage === 'footer') {
        this.displayBlueBackground = true
      }
      else  this.displayBlueBackground = false



      if(this.currentStage === 'marketPlace') {
        this.displayPurpleBackground = true
      }
      else  this.displayPurpleBackground = false

      if(this.currentStage === 'metaHuman') {
        this.displayMetaHumanBackground = true
      }
      else  this.displayMetaHumanBackground = false



      if(this.currentStage === 'concept') {
        this.displayConceptBackground = true
      }
      else  this.displayConceptBackground = false






       this.displayShader = global.elementVisibilityStatus('#topCaptions') === 0

       this.darkBackground = this.displayShader
      if(this.displayShader) {
        this.displayHeroImage = false
      }
      else {
        this.displayHeroImage = true;
      }



    },


    // listen event
    onPlayerPlay(player) {
        console.log('player play!', player)
    },
    onPlayerPause(player) {
      console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
       console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
    closeVideoPlayer() {
      this.videoIsPlaying = false
      this.toggleVideo()
    },
    openVideoPlayer() {
      this.videoIsPlaying = true
      this.toggleVideo()
    },
    playVideoPlayer() {
      this.$refs.videoPlayer.play()
      this.videoState = 'play_XXXXXXXXXXX'
    },
    pauseVideoPlayer() {
      this.$refs.videoPlayer.pause()
      this.videoState = 'pause'
    },
    toggleVideo() {


      const video = document.getElementById("visionVideo");


      if(!video) {
        return
      }
      if (video.paused) {
        // Play the video
        video.play();
      } else {
        // Pause the video
        video.pause();
      }
    },
    goTo() {
      global.scrollTo('header', 1000)
    }



  },
  mounted() {

    console.log(document.getElementById('app'))
    global.mainScrollBar  = Scrollbar.init(document.getElementById('app'), {
      dumping: 0.05
    });

    global.mainScrollBar.track.xAxis.element.remove();

    global.mainScrollBar.addListener((status) => {
      global.mainScrollBar.setPosition(0, status.offset.y)
      this.scrollPosition = (this.scrollPositionBase - status.offset.y)
      global.globalScrollPosition = (this.scrollPositionBase - status.offset.y)


      this.scrollManager(status)



      //   this.$refs.videoPlayer.player.controlBar.progressControl.disable();

    });


    setTimeout(() => {
     // global.scrollTo('footer')
    },10);

    this.height = window.innerHeight;
    this.width = window.innerWidth;

    if(this.width < 700) {
      this.scrollPositionBase = 250
    }








    const elmHamburger = document.querySelector('.hamburger');
    const gNavItems = document.querySelectorAll('.global-menu__item');
    const elmOverlay = document.querySelector('.shape-overlays');

    const overlay = new ShapeOverlays(elmOverlay);

    elmHamburger.addEventListener('click', () => {
      if (overlay.isAnimating) {
        return false;
      }
      overlay.toggle();
      if (overlay.isOpened === true) {
        elmHamburger.classList.add('is-opened-navi');
        for (let i = 0; i < gNavItems.length; i++) {
          gNavItems[i].classList.add('is-opened');
        }
      } else {
        elmHamburger.classList.remove('is-opened-navi');
        for (let i = 0; i < gNavItems.length; i++) {
          gNavItems[i].classList.remove('is-opened');
        }
      }
    });



  },
  computed: {
    itemMoverStyle() {
      return {
        "transform": `translate3d(${this.scrollPosition}px, 0px, 0px)`,
        "font-size": '124px',
        "font-weight": `bold`,
        "text-transform": 'uppercase',
        "transition-property": 'transform',
        "transition-timing-function": 'cubic-bezier(0.15, 0, 0.55, 1)',
        "transition-duration": '0s',
        "transition-delay": '0s',
      };
    },
    heightStyle() {
      return {
        "height": `${this.height}px`
      };
    },
    player() {
      return this.$refs.videoPlayer.player
    }
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&family=Source+Sans+Pro&display=swap');

:root {
  --width: 100%;
  --height: -800px;
  --navBarSize: 96px;
}


body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}
.leftSideBar {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 80px;
  min-width: 72px;
  max-width: 72px;
  background: #58396b66;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 0;
  z-index: 2;
}
.topImage {
  height: 100%;
  width: 700px;
  right: -50px;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
  position: fixed;
  transition-duration: 0.4s;


}


@media only screen and (max-width: 900px) {
  .topImage {
    display: none;
  }
}








.hexagon-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url('./assets/images/hexagon.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}



.purple-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(33,4,54,1);
  background-image: url("./assets/images/earth.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.meta-human-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(33,4,54,1);
  background-image: url("./assets/images/metahuman1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.concept-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(33,4,54,1);
  background-image: url("./assets/images/utopia.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.overlap {
  animation-name: bgAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  opacity: 0.5;
  transition-timing-function: linear;
}

@keyframes bgAnimation {
  0% {
    transform: translate3d(0, 0px, 0);
    opacity: 0.2;
  }
  100% {
    transform: translate3d(-50px, 5px, 0);
    opacity: 0.5;
  }
}


/* Glitch styles */
.glitch {

  --color-text: #aaa;
  --color-bg: #000;
  --color-link: #1c1cc9;
  --color-link-hover: #aaa;
  --color-info: #1c1cc9;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --color-title: #fff;
  --time-anim: 2s;
  --delay-anim: 0s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: overlay;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: #fb909a;
  --blend-color-5: #1c1cc9;



  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.glitch__img {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/images/hero.png') no-repeat right bottom;
  background-color: var(--blend-color-1);
  background-size: contain;
  transform: translate3d(0,0,0);
  background-blend-mode: var(--blend-mode-1);
}

.glitch__img:nth-child(n+2) {
  opacity: 0;
}

.imgloaded .glitch__img:nth-child(n+2) {
  animation-duration: var(--time-anim);
  animation-delay: var(--delay-anim);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.imgloaded .glitch__img:nth-child(2) {
  background-color: var(--blend-color-2);
  background-blend-mode: var(--blend-mode-2);
  animation-name: glitch-anim-1;
}

.imgloaded .glitch__img:nth-child(3) {
  background-color: var(--blend-color-3);
  background-blend-mode: var(--blend-mode-3);
  animation-name: glitch-anim-2;
}

.imgloaded .glitch__img:nth-child(4) {
  background-color: var(--blend-color-4);
  background-blend-mode: var(--blend-mode-4);
  animation-name: glitch-anim-3;
}

.imgloaded .glitch__img:nth-child(5) {
  background-color: var(--blend-color-5);
  background-blend-mode: var(--blend-mode-5);
  animation-name: glitch-anim-flash;
}

/* Animations */

@keyframes glitch-anim-1 {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal),0,0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal),0,0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}


/* Flash */
@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: 0.2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
  }
  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}



.videoPlayer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.videoPlayerClose {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: pointer;
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;
}
.videoPlayerControl {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  cursor: pointer;
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;
}
.videoPlayerClose :hover {
  color: white;

  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
}
</style>
