<template>

  <b-row >
    <div class="--pattern-title" style="margin-top: 124px;margin-bottom: 124px">
      <div class="A-pattern-title__mover " :style="itemMoverStyle">
        <div  v-for="i in 15" :key="i">

          <span class="A-pattern-title__item " style="margin-right:4rem;">{{ stroke }}</span>
         
          <span class="A-pattern-title__item" style="margin-right:4rem;">{{ fill }}</span>
        </div>
      </div>

    </div>
  </b-row>
</template>


<script>



export default {
  name: 'RollingText',
  props: {
    fill: String,
    stroke: String
  },
  components: {

  },
  created() {





  },
  mounted() {
    this.$root.$on('onScroll', (status) => {
      this.scrollPosition = (this.scrollPositionBase - status.offset.y)
    });

    this.height = window.innerHeight;
    this.width = window.innerWidth;

    if(this.width < 700) {
      this.scrollPositionBase = 250
    }


  },
  destroyed() {

  },
  computed: {
    itemMoverStyle() {
      return {
        "transform": `translate3d(${this.scrollPosition}px, 0px, 0px)`,
        "font-size": '9rem',
        "font-weight": `bold`,
        "text-transform": 'uppercase',
        "transition-property": 'transform',
        "transition-timing-function": 'cubic-bezier(0.15, 0, 0.55, 1)',
        "transition-duration": '0s',
        "transition-delay": '0s',
      };
    },
    heightStyle() {
      return {
        "height": `${this.height}px`
      };
    }
  },

  data() {
    return {
      scrollPositionBase: 250,
      scrollPosition: global.globalScrollPosition,

    };
  },
  methods: {
  },
}
</script>

<style>



</style>
