<template>

  <b-row >
    <b-row  >
      <b-col class="shader">
      <b-row cols-lg="frame">

        <section class="token " style="min-height: calc(100vh - 7rem);  margin-top: 48px;"  >


          <h3 class="upper  nexaExtraBold fontsize-32 text-gradient title" id="walletCustody">
            Wallet Custody
          </h3>

          <b-row>
            <b-col cols="12" sm="12" md="12" lg="6" xl="6"  style="padding-left: 66px;">
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Next generation custodian</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Multi Signature</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>On-Chain Custodian</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Isolated environment</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Full control over your assets</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Defense in Depth</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Fast and reliable</span>
                </b-col>
              </b-row>


              <b-row class="" align-h="start"   >
                <div class="d-flex align-items-start flex-column" >
                 <a href="https://app.unfxbit.com" target="_blank" style="width: 100%;max-width: 350px;margin-top: 42px;margin-bottom: 42px">
                   <div   style="width: 100%" >
                     <div class="rect-button">
                       <div     class="rect-button text-light bg-accent-custom bg-primary-custom" >
                         <span class="nexaExtraBold"> Open App  </span>
                       </div>
                     </div>

                   </div>
                 </a>
                </div>
              </b-row>



            </b-col>
            <b-col  cols="12" sm="12" md="12" lg="6" xl="6"  class="gallery" >
                  <b-row   class=" d-flex justify-content-center align-items-center" style="padding-left: 32px">
                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/android.png"  alt="icon" />
                    </b-col>
                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/ios.png" alt="icon" />
                    </b-col>

                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/windows.png" alt="icon" />
                    </b-col>
                    <b-col cols="3" class="  exchangeTechItem">
                      <img src="@/assets/images/exchange/techs/generation.png" alt="icon" />
                    </b-col>

                    <b-col cols="3" class=" exchangeTechItem">
                      <img src="@/assets/images/exchange/techs/linux.png" alt="icon" />
                    </b-col>
                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/multi-signature.png" alt="icon" />
                    </b-col>
                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/fast.png" alt="icon" />
                    </b-col>

                    <b-col cols="3" class=" exchangeTechItem">
                      <img src="@/assets/images/exchange/techs/isolation.png" alt="icon" />
                    </b-col>
                    <b-col cols="3" class=" exchangeTechItem ">
                      <img src="@/assets/images/exchange/techs/control.png" alt="icon"  />
                    </b-col>


                  </b-row>
            </b-col>
            <div  id="walletCustodyEnd"></div>
          </b-row>





        </section>

      </b-row>
    </b-col>
    </b-row>
  </b-row>
</template>


<script>

export default {
  name: 'WalletCustody',
  components: {
  },
  created() {

  },
  mounted() {



    setTimeout(() => {
      global.mainScrollBar.addListener(() => {
      });
    },1000);



  },
  destroyed() {

  },

  data() {
    return {

      slide: 0,
      sliding: null,
      title: ''
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
}
</script>

<style>
.highcharts-label text {
  color: #ab198c;
  fill: #0BFFBF;
}

.rect-button {
  cursor: pointer;
  --cursor: hover;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  min-width: 200px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 0;
  text-transform: uppercase;
  color:white;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  -webkit-clip-path: polygon(0 calc(100% - 1.25rem),0 0,calc(100% - 1.25rem) 0,100% 1.25rem,100% 100%,1.25rem 100%);
  clip-path: polygon(0 calc(100% - 1.25rem),0 0,calc(100% - 1.25rem) 0,100% 1.25rem,100% 100%,1.25rem 100%);


  background: #F13FA8;
  background: linear-gradient(to right,#F13FA8,#04A9FA);
  background: -webkit-linear-gradient(to right,#f13fa8 0%,#04a9fa 100%);

}

.rect-button:hover {
  color: #09a17b;
}


</style>
