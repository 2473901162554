<template>

  <b-row >
    <b-row >
      <section   >
          <b-row class="token  footer" id="footer" >


        <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="intro" >

          <b-row>
            <b-col cols="12"  class="d-flex justify-content-start align-content-start align-items-start  ">
              <h1 class="text-gradient" style="font-size: 32px">
                <img src="../assets/images/unfxbit-logo.png" alt="unfxbit logo" style="rotate: 130deg">
                UNFXBIT.COM
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12"  class="d-flex justify-content-start align-content-start align-items-start mt-3 textLeft ">
              <p  >
                UNFXBIT offers digital asset management tools and a blockchain-based business development platform.
              </p>
            </b-col>
          </b-row>



        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="intro">
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top: 12px">

              <b-row>
                <b-col cols="12"  class="d-flex justify-content-start align-content-start align-items-start  ">
                  <h1 class="">   Navigation  </h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12"  class="    mt-5 textLeft ">
                  <span   class="hand" @click="scrollTo('header')" >Get Started</span> <br/>
                  <span   class="hand" @click="scrollTo('services')">Services</span> <br/>
                  <span   class="hand" @click="scrollTo('exchange')" >Exchange</span> <br/>
                  <span   class="hand" @click="scrollTo('walletCustody')" >Wallet Custody</span> <br/>
                  <span   class="hand" @click="scrollTo('explorer')" >Blockchain Explorer</span> <br/>
                  <span   class="hand" @click="scrollTo('nodeView')" >Blockchain Infrastructure</span> <br/>
                  <span   class="hand" @click="scrollTo('contactUs')" >Contact us</span> <br/>
<!--                  <span   class="hand" @click="scrollTo('roadMap')" >Roadmap</span> <br/>
                  <span   class="hand" @click="scrollTo('partners')" >Partners</span> <br/>-->
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6"  style="margin-top: 12px">

              <b-row>
                <b-col cols="12"  class="d-flex justify-content-start align-content-start align-items-start  ">
                  <h1 class="">   Apps  </h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mt-5 textLeft">
                  <a href="#" >Windows</a> <br/>
                  <a href="#" >MacOS</a> <br/>
                  <a href="#" >Android</a> <br/>
                  <a href="#" >Ios</a> <br/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-row class="mt-5 mb-5">
          <b-col>
            <hr style="height: 1px;opacity: 0.1"/>

            <b-row style="padding: 8px">
              <b-col cols="6" class="  d-flex justify-content-start" style="font-family: Montserrat, Arial, serif; font-weight: bold;white-space: nowrap;">
                ©{{ year }} UNFXBIT.com
              </b-col>
              <b-col cols="6" class=" d-flex justify-content-end social d-none">
                    <a href="#" target="_blank">
                      <img src="../assets/images/social/facebook.svg" alt="facebook"/>
                    </a>
                    <a href="#" target="_blank">
                      <img src="../assets/images/social/instagram.svg" alt="instagram"/>
                    </a>
                    <a href="#" target="_blank">
                      <img src="../assets/images/social/twitter.svg" alt="twitter"/>
                    </a>
                    <a href="#" target="_blank">
                      <img src="../assets/images/social/github.svg" alt="github"/>
                    </a>
                    <a href="#" target="_blank">
                      <img src="../assets/images/social/youtube.svg" alt="youtube"/>
                    </a>
              </b-col>
            </b-row>



          </b-col>
        </b-row>

      </b-row>
      </section>

  </b-row>
  </b-row>
</template>


<script>



export default {
  name: 'Footer',
  components: {

  },
  created() {

  },
  mounted() {
  },
  destroyed() {

  },

  data() {
    return {
          year: (new Date()).getFullYear()


    };
  },
  methods: {
     scrollTo(id) {
      console.log(id)
      setTimeout(() => {
        global.scrollTo(id)
      }, 500);

    },
  },
}
</script>

<style>

.footer {
  background-color: #0B0C27;
  color: white;
  min-height: 400px;
  padding-top: 96px;
  padding-left: 48px;
  padding-right: 48px;
  font-family: Nexa, Arial,serif;
}
.footer .intro {
  padding: 24px;
}
.footer .intro h1 {
  font-family: Montserrat, Arial,serif;
  font-weight: bold;
  font-size: 28px;
}
.footer .intro img {
  width: 48px;
  height: 48px;
}

.footer .intro a {
  font-size: 18px;
  padding: 4px;
  margin-top: 18px;
  font-family: Nexa, Arial,serif;
}

.social a {
  margin: 8px;
}



@media screen and (max-width : 576px) {

  .footer .intro img {
    width: 100%;
    height: auto;
    padding: 16px;
  }

}

</style>
