<template>


  <b-row >
    <b-row  >
      <b-col class="shader">
        <b-row cols-lg="frame">
          <section class="token " style="min-height: calc(100vh - 7rem);  margin-top: 48px;"  >


          <h3 class="upper  nexaExtraBold fontsize-32 text-gradient title" id="nodeView">
            Blockchain Infrastructure
          </h3>

          <b-row>
            <b-col cols="12" sm="12" md="12" lg="6" xl="6"  style="padding-left: 66px;">
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Free, Premium & Enterprise</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Easy to use API</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Realtime socket API</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Technical support</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>1 Endpoint, All blockchains</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="tokenUtility d-flex justify-content-start align-items-center">
                  <img src="../assets/images/icons/ticket.svg" alt="diamond"/>
                  <span>Historical data</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="tokenUtility d-flex justify-content-start align-items-center">
                  <custom-button link="contactUs" text="Contact us" variant="bg-accent-custom" theme="dark" class="mt-2 mb-auto p-2"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col  cols="12" sm="12" md="12" lg="6" xl="6" class="gallery"  >
              <b-row   class=" d-flex justify-content-center align-items-center" style="padding-left: 32px">
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/reliable.png"  alt="icon"/>
                </b-col>
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/api.png"  alt="icon"/>
                </b-col>

                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/performance.png"   alt="icon"/>
                </b-col>
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/socket.png"   alt="icon"/>
                </b-col>
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/support.png"   alt="icon"/>
                </b-col>
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/endpoint.png"   alt="icon"/>
                </b-col>
                <b-col cols="3" class=" exchangeTechItem ">
                  <img src="@/assets/images/exchange/techs/history.png"   alt="icon"/>
                </b-col>

              </b-row>
            </b-col>
            <div  id="nodeViewEnd"></div>
          </b-row>





        </section>
      </b-row>
    </b-col>
    </b-row>
  </b-row>
</template>


<script>

import CustomButton from "@/components/Button.vue";

export default {
  name: 'NodeView',
  components: {
    CustomButton
  },
  created() {

  },
  mounted() {



    setTimeout(() => {
      global.mainScrollBar.addListener(() => {
      });
    },1000);



  },
  destroyed() {

  },

  data() {
    return {

      slide: 0,
      sliding: null,
      title: ''
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
}
</script>

<style>
.highcharts-label text {
  color: #ab198c;
  fill: #0BFFBF;
}


</style>
